'use client';

import { useCallback, useMemo } from 'react';
import { invert } from 'lodash-es';
import { parseAsInteger, useQueryState } from 'nuqs';
import { EntryListFilterType, EntryListQuery, EntryListSort } from './useEntryList';

export const paramsTypeToQueryType: Record<string, EntryListFilterType> = {
  kaikki: EntryListFilterType.UsedAndNew,
  kaytetyt: EntryListFilterType.OnlyUsed,
  uudet: EntryListFilterType.OnlyNew,
};

const paramsSortToQuerySort: Record<string, EntryListSort> = {
  paattyvat: EntryListSort.Ending,
  katsotuimmat: EntryListSort.MostViewed,
  uusimmat: EntryListSort.Newest,
};

const sortTabToQuerySort: Record<number, EntryListSort> = {
  0: EntryListSort.Ending,
  1: EntryListSort.MostViewed,
  2: EntryListSort.Newest,
};

const querySortToParamsSort = invert(paramsSortToQuerySort);
export const querySortToSortTab = invert(sortTabToQuerySort);
export const queryTypeToParamsType = invert(paramsTypeToQueryType);

export const useListFilterParams = (
  queryFilter: Partial<EntryListQuery['filter']>,
  queryInitialSort: EntryListSort = EntryListSort.Ending,
  queryLimit: number = 40
) => {
  const [querySort, setQuerySort] = useQueryState('jarjestys', {
    parse: (value) => paramsSortToQuerySort[value] ?? null,
    serialize: (value) => querySortToParamsSort[value],
  });

  const [queryPage, setQueryPage] = useQueryState('sivu', parseAsInteger);

  const onSortChange = useCallback(
    (index: number) => {
      void setQuerySort(sortTabToQuerySort[index]);
      void setQueryPage(1);
    },
    [setQuerySort, setQueryPage]
  );

  const onPageChange = useCallback(
    ({ selected }: { selected: number }) => {
      void setQueryPage(selected + 1);
      window?.scrollTo({ top: 0 });
    },
    [setQueryPage]
  );

  const query = useMemo(() => {
    return {
      filter: queryFilter,
      page: queryPage ?? 1,
      sort: querySort ?? queryInitialSort,
      limit: queryLimit,
    } as EntryListQuery;
  }, [queryFilter, queryInitialSort, queryLimit, queryPage, querySort]);

  return {
    query,
    onSortChange,
    setQueryPage,
    onPageChange,
  };
};
