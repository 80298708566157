import React, { forwardRef } from 'react';
import { Select, SelectProps } from '@mezzoforte/forge';
import manufacturers from '../../data/manufacturers.json';
import { groupBy, sortBy } from 'lodash-es';

const topManufacturers = ['BMW', 'Audi', 'Mercedes-Benz', 'Volvo', 'Toyota', 'Ford', 'Honda', 'Volkswagen', 'Nissan'];

export const ListPageManufacturerSelect = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const groupedManufacturers = groupBy(manufacturers.passengerCar, (name) => name.charAt(0).toUpperCase());

  const groupedOptions = [
    {
      name: 'Suosituimmat',
      options: topManufacturers.map((name) => ({ label: name, value: name })),
    },
    ...sortBy(Object.entries(groupedManufacturers), ([char, _]) => char).map(([char, names]) => ({
      name: char,
      options: names.map((name) => ({ label: name, value: name })),
    })),
  ];

  return (
    <Select ref={ref} {...props}>
      <>
        <option value="">Kaikki</option>
        {groupedOptions.map((group) => (
          <optgroup key={group.name} label={group.name}>
            {group.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </optgroup>
        ))}
      </>
    </Select>
  );
});
