import { useQuery } from '@tanstack/react-query';
import { RegionAPIResponse, RegionsAPIResponse } from 'types/ApiResponse';
import { Region } from 'types/Region';
import { useEnv } from './useEnv';

function regionFromAPIResponse(responseItem: RegionAPIResponse): Region {
  return responseItem;
}

export async function fetchRegions(baseUrl: string) {
  try {
    const response = await fetch(`${baseUrl}/api/regions`, {
      next: { revalidate: 60 * 10 },
    });
    const data = (await response.json()) as RegionsAPIResponse;
    return data.map(regionFromAPIResponse);
  } catch (e) {
    console.warn('Fetching regions failed');
    return [];
  }
}

export function useRegions() {
  const { baseUrl } = useEnv();

  return useQuery<Region[]>({
    queryKey: ['regions'],
    queryFn: () => fetchRegions(baseUrl),
    staleTime: 1000 * 60 * 10,
  });
}
