'use client';

import React from 'react';
import { Box, Flex, Heading, Text, VisuallyHidden } from '@mezzoforte/forge';
import EntryCard from './EntryCard';
import DummyEntryCard from './DummyEntryCard';
import { ListAnalytics, ListEntry } from '../../types/ListEntry';
import { EntryListContainer } from './EntryListContainer';
import { BoxProps } from '@chakra-ui/react';

export interface DummyItem {
  type: 'Dummy';
  key: string;
}

export interface EntryItem {
  type: 'Entry';
  entry: ListEntry;
  key: string;
}

export type ListItem = DummyItem | EntryItem;
type MapResult = [number, ListItem];

const Dummy = React.memo(DummyEntryCard);

function renderItem(item: ListItem, analytics: ListAnalytics, index: number) {
  if (item.type === 'Entry')
    return (
      <EntryCard
        entry={item.entry}
        key={item.key}
        analytics={{ listId: analytics.listId, listName: analytics.listName, index }}
      />
    );

  return <Dummy key={item.key} />;
}

type EntryListProps = BoxProps & {
  readonly entries?: ListEntry[];
  readonly dummyEntryCount: number;
  readonly analytics: ListAnalytics;
  readonly emptyStateElement?: React.JSX.Element;
};

function EmptyState() {
  return (
    <Flex w="100%" direction="column" alignItems="center" py={6} gap={2}>
      <Heading variant="h3">Ei ilmoituksia</Heading>
      <Text>Kokeile yleisempiä hakuehtoja.</Text>
    </Flex>
  );
}

export function EntryList({ entries, emptyStateElement, dummyEntryCount, analytics, ...rest }: EntryListProps) {
  const shouldShowDummies = entries === undefined;

  const items: MapResult[] = (shouldShowDummies ? [...Array(dummyEntryCount)] : entries).map((entry, index) => [
    index + 1,
    shouldShowDummies ? { type: 'Dummy', key: `dummy-${index}` } : { type: 'Entry', entry, key: entry.id.toString() },
  ]);

  if (!shouldShowDummies && items.length === 0) {
    return emptyStateElement || <EmptyState />;
  }

  return (
    <Box {...rest}>
      <VisuallyHidden>
        <Heading variant="h2">Kohteet</Heading>
      </VisuallyHidden>
      <EntryListContainer
        data-test="entry-list-container"
        display="grid"
        gridAutoFlow="dense"
        gridTemplateColumns={{ base: '1fr', md: 'repeat(auto-fill, minmax(300px, 1fr))' }}
      >
        {items.map(([index, item]) => renderItem(item, analytics, index))}
      </EntryListContainer>
    </Box>
  );
}
